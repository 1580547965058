.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* SOCIAL ICONS */

.LinkedIn {
  color:  #2867B2;
}

.Twitter {
  color: #1DA1F2;
}
